import React from 'react';

const Home: React.FC = () => {
  return (
    <div>
      <h1>Bienvenido al Dashboard MDM</h1>
      <p>Solo los usuarios con rol admin pueden ver esta página.</p>
    </div>
  );
};

export default Home;
