// src/pages/Orders.tsx
import React from 'react';
import OrdersTable from '../components/tables/OrdersTable';

const Orders: React.FC = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Pedidos</h1>
      <OrdersTable />
    </div>
  );
};

export default Orders;
