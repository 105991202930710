 //src/components/PrivateRoute.tsx
 import React from 'react';
 import { Navigate } from 'react-router-dom';
 
 interface PrivateRouteProps {
   children: React.ReactElement;
   roles: string[];
   token: string | null;
   userRole: string | null;
 }
 
 const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, roles, token, userRole }) => {
   if (!token || !roles.includes(userRole ?? '')) {
     return <Navigate to="/login" />;
   }
   return children;
 };
 
 export default PrivateRoute;
 