// src/components/OrdersTable.tsx
import React, { useEffect, useMemo, useState } from 'react';
import { useTable, Column } from 'react-table';
import axios from 'axios';

interface Order {
  id: number;
  customer_name: string;
  customer_last_name: string;
  customer_full_name?: string;
  date_created: string;
  status: string;
  guia: string | null;
  total: string;
  origin_order_id: number;
  store_name: string;
  tipo_documento: string;
}

const OrdersTable: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/list`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }); 
        const formattedOrders = response.data.map((order: Order) => ({
          ...order,
          date_created: new Date(order.date_created).toLocaleString(), 
          customer_full_name: `${order.customer_name} ${order.customer_last_name}`
        }));
        setOrders(formattedOrders);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  const columns: Column<Order>[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id' as const,
      },
      {
        Header: 'Nombre Completo',
        accessor: 'customer_full_name' as const,
      },
      {
        Header: 'Fecha de Creación',
        accessor: 'date_created' as const,
      },
      {
        Header: 'Estado',
        accessor: 'status' as const,
      },
      {
        Header: '# Guía',
        accessor: 'guia' as const,
      },
      {
        Header: 'Total',
        accessor: 'total' as const,
      },
      {
        Header: 'ID Origen',
        accessor: 'origin_order_id' as const,
      },
      {
        Header: 'Tienda',
        accessor: 'store_name' as const,
      },
      {
        Header: 'Tipo Documento',
        accessor: 'tipo_documento' as const,
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data: orders });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
                key={column.id}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={row.id}>
              {row.cells.map(cell => (
                <td
                  {...cell.getCellProps()}
                  key={cell.column.id}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default OrdersTable;
