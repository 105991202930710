//src/components/Dashboard.tsx
import React from 'react';
import logo from '../assets/images/Logo_mdm.webp'; 
import { Link, useNavigate,Outlet } from 'react-router-dom';

interface DashboardProps {
  onLogout: () => void;
}

const Dashboard: React.FC<DashboardProps> = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col">
        <Header onLogout={handleLogout} />
        <main className="flex-1 overflow-y-auto p-4">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

const Sidebar: React.FC = () => {
  return (
    <div className="w-64 bg-white shadow-md">
      <div className="p-4 font-bold text-xl">
        <img src={logo} alt="Logo" className="w-36 h-auto mx-auto my-2" />
      </div>
      <nav className="mt-5">
        <ul>
          <li className="p-4"><Link to="/home" className="hover:text-blue-600">Home</Link></li>
     {/*  <li className="p-4"><Link to="/profile" className="hover:text-blue-600">Profile</Link></li>
          <li className="p-4"><Link to="/settings" className="hover:text-blue-600">Settings</Link></li> */}
          <li className="p-4"><Link to="/orders" className="hover:text-blue-600">Pedidos</Link></li>
        </ul>
      </nav>
    </div>
  );
};

const Header: React.FC<{ onLogout: () => void }> = ({ onLogout }) => {
  return (
    <header className="bg-white shadow-md p-4">
      <div className="flex justify-between items-center">
        <div className="font-bold text-xl">Dashboard MDM</div>
        <button 
          onClick={onLogout} 
          className="bg-custom-blue text-white py-2 px-4 rounded hover:bg-custom-blue-2 transition duration-300">
          Cerrar Sesión
        </button>
      </div>
    </header>
  );
};

export default Dashboard;