// src/api/authService.ts
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

export const login = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/login`, { email, password });
    return response.data;
  } catch (err) {
    console.error('Error en la solicitud de inicio de sesión:', err);
    throw new Error('Error en el inicio de sesión');
  }
};

export const refreshToken = async (token: string) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/token`, { token });
    return response.data.accessToken;
  } catch (err) {
    console.error('Error en la solicitud de refresco de token:', err);
    throw new Error('Error en el refresco del token');
  }
};
